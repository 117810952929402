$display-font-sizes: (
  1: 4.5rem,
  2: 4rem,
  3: 3.625rem,
  4: 3.25rem
);

$utilities-bg-colors: map-merge(
  $utilities-bg-colors,
  (
    'blue': $blue,
    'blue-050': $blue-050,
    'purple': $purple,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'yellow': $yellow,
    'gray-100': $gray-100,
    'gray-200': $gray-200,
    'gray-300': $gray-300,
    'gray-400': $gray-400,
    'gray-500': $gray-500,
    'gray-600': $gray-600,
    'gray-700': $gray-700,
    'gray-800': $gray-800,
    'gray-900': $gray-900
  )
);

$utilities-text-colors: map-merge(
  $utilities-text-colors,
  (
    'yellow': $yellow,
    'gray-930': $gray-930
  )
);

$spacers: map-merge(
  $spacers,
  (
    6: $spacer * 3.5,
    7: $spacer * 4,
    8: $spacer * 4.5,
    9: $spacer * 5,
    10: $spacer * 5.5,
  )
);

.align-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
