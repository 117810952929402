a:not(.btn) {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

small {
  @include font-size(14px);
}

@each $size, $value in $headings-line-height-map {
  .#{$size}, #{$size} {
    @include rfs($value, line-height);
  }
}
