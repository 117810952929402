:root,
[data-bs-theme='light'] {
  --#{$prefix}font-poppins: #{$font-family-poppins};
}

.bootstrap-ui-kit {
  @each $color, $value in $theme-colors-bg-subtle {
    --#{$prefix}#{$color}-subtle: #{$value};
  }
}
