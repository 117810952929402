.image-class {
    height: 350px;
    border-radius: 20px;
    object-fit: cover;
    object-position: top;
}

.custom-service {
  @include media-breakpoint-up(md) {
    padding-left: 12px; /* gutter size */
    background-clip: padding-box;
    width: 100%;

    // .list-group-item {
    //   span {
    //     @include font-size(12.8px);
    //   }
    // }
  }
  margin-bottom: 12px;

  .list-group-item {
    span {
      @include font-size(12.8px);
    }
  }
  .card-header {
    @include font-size(24px); 
    font-weight: 600;
  }

  .list-group {
    background-color: transparent;
    border: 0;

    &-item {
      background-color: transparent;
      border: 0;
    }
  }
}

// Custom bg colors
.bg-card1 {
    background-color: #b5a1cd !important;
}
.bg-card2 {
    background-color: #f49cc2 !important;
}
.bg-card3 {
    background-color: #f062a4 !important;
}
.bg-card4 {
    background-color: #ec098c !important;
}
.bg-card5 {
    background-color: #92278f !important;
}
.bg-card6 {
    background-color: #9f1e63 !important;
}

.bg-card5, .bg-card6, .bg-card4 {
  .card-header, .list-group-item {
    color: $white !important;
  }
}

.page {
  .bg-card5, .bg-card6, .bg-card4 {
    .list-group-item {
      color: $black !important;
    }
  } 
}
.my-masonry-grid {
  @include media-breakpoint-up(md) {
    display: flex;
  }
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  margin-left: -30px; /* gutter size offset */
  width: auto;

  img {
      width: 100%;
      height: auto;
  }
  
  &_column {
    @include media-breakpoint-up(md) {
      width: 50%;
    }
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
    width: 100%;

    > div { /* change div to reference your elements you put in <Masonry> */
        margin-bottom: 30px;
    }
  }
}