@import "../abstracts/variables";
.schedule-form {
    @include media-breakpoint-up(md) {
        border-radius: 1.5rem;
    }
    margin-top: 2rem;
    padding: 3rem;
    background-color: rgba($secondary, 0.4);
    .form-label {
        margin-bottom: 1rem;
        font-size: 1rem;
        font-weight: 500;
    }
}

.custom-list {
    li {
        margin-bottom: 1.2rem;
        display: flex;
        align-items: center;
        .fa-phone {
            color: $primary;
        }
        .fa-envelope {
            color: $gray-930;
        }
        .fa-whatsapp {
            color: $green;
        }
    }
}