.video-size {
  @include media-breakpoint-up(md) {
    width: 80%;
  }
  width: 100%;
  height: 500px;
  margin: 0 auto;

  iframe {
    width: auto;
    height: 100%;
  }
}
