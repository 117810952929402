.btn {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  @include border-start-radius(25px);
  @include border-end-radius(25px);

  &-primary {
    @extend %btn-primary;
  }

  &-secondary {
    @extend %btn-secondary;
  }

  &--wide {
    @include padding(10px 26px);
  }
}

%btn-primary {
  color: $black !important;
  background-color: $primary !important;

  &:hover,
  &.active {
    background-color: $secondary !important;
  }
}

%btn-secondary {
  color: $black !important;
  background-color: $secondary !important;

  &:hover,
  &.active {
    color: $primary;
    background-color: $white;
  }
}
