.hero-banner {
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    opacity: 1;
    position: relative;
    top: 0;
    transition: opacity .4s linear;
    width: 100%;
    height: 50vh;

    @include media-breakpoint-up(md) {
        height: 80vh;
    }

    .heading-container {
        background: rgba(87, 82, 79, 0.7960784314);
        border-top: 1px solid #fff;
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        height: 25vh;

        @include media-breakpoint-up(md) {
            height: 20vh;
        }

        .heading-title {
            margin-top: .5em;
        }

        h4 {
            color: #37ca37;
        }
    }
}

.bg-cover-image {
    background-size: cover;
    background-position: bottom;
    background-attachment: fixed;
    background-repeat: repeat;
    background-color: black;
    @include media-breakpoint-up(md) {
        background-size: cover;
        background-position: center;
    }
}

.title-container {
    background-color: rgba($gray-400, 0.7);
    padding: 20px;
}