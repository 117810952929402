.carousel {
  
  &-container {
    @include media-breakpoint-up(md) {
      @include padding(40px 0 40px 0);
      height: 60vh;
    } 
    height: auto;
  }

  &-image {
    @include media-breakpoint-up(md) {
      @include border-radius(15px);
    }

    height: 50vh;
    width: 100%;
    object-fit: cover;

    @include media-breakpoint-up(md) {
      width: auto;
      height: 100vh;
    }
  }

  &-caption {
    background-color: rgba($gray-900, 0.7);
    left: 10% !important;
    right: 10% !important;
  }
}
