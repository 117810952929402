.banner-wrapper {
  overflow: hidden;

  h1 {
    line-height: rfs-value(48px);
  }

  p {
    line-height: rfs-value(28px);
  }

  .eyebrow {
    @include font-size(16px);
    font-family: $font-family-poppins;
  }

  .btn-secondary {
    color: $primary-tea-navy-dark;
    background-color: $white;

    &:hover {
      color: $white;
      background-color: $primary-tea-navy-dark;
    }
  }

  .bg-image {
    background-size: cover;
    background-position: center;
    position: relative;

    svg {
      position: absolute;
      bottom: -1px;
    }

    .svg-height-image {
      height: 600px;

      @include media-breakpoint-up(lg) {
        height: auto;
      }
    }

    .container {
      position: relative;

      .btn {
        align-self: flex-start;
      }
    }
  }

  .min-height-image {
    @include media-breakpoint-up(lg) {
      min-height: 560px;
    }
  }

  .min-height-no-image {
    @include media-breakpoint-up(lg) {
      min-height: 375px;
    }
  }

  .min-height-no-image-no-text {
    @include media-breakpoint-up(lg) {
      min-height: 280px;
    }
  }
}
