@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

// Bootstrap variables
// scss-docs-start theme-color-variables
$primary: #9abdff;
$secondary: #5ca7e4;
$success: #6c757d;
$info: #00abba;
$warning: #ecaf33;
$danger: #cd3103;

// scss-docs-start theme-bg-subtle-variables
$primary-bg-subtle: #d9dee9;
$warning-bg-subtle: #f9e7c2;

// scss-docs-start color-variables
$blue: $secondary;
$blue-050: #f0f6fb;
$blue-100: #f8fbfd;
$purple: #704280;
$green: #92c740;
$teal: #00abba;
$cyan: #d7f7e0;
$yellow: #fffec3;
$black: #000;
$dark-blue: #175f99;
$primary-tea-navy-dark: #012169;

// scss-docs-start gray-color-variables
$gray-080: #eceef4;
$gray-090: #f0f0f0;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #8f99a4;
$gray-600: #6c757d;
$gray-650: #707070;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$gray-910: #363534;
$gray-920: #3a3632;
$gray-930: #5a6267;

// scss-docs-start font-variables
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-poppins: 'Poppins', sans-serif;
$lead-font-size: 1.125rem;  // 18px
$font-size-small: 0.875rem; // 14px
$font-weight-bolder: 900;
$lead-font-weight: 400;

$line-height-base: 1.75;

$h3-font-size: 1.25rem;         // 20px
$h4-font-size: $lead-font-size; // 18px
$h5-font-size: 1rem;            // 16px

// scss-docs-start headings-variables1
$headings-font-family: $font-family-poppins;
$headings-color: $gray-920;
$headings-font-weight: 500;
$headings-line-height: 1.4;
$headings-line-height-map: (
  'h2': 35px
);

// scss-docs-start display-headings
$display-font-weight: 700;

// scss-docs-start btn-variables
$btn-padding-y: 0.8125rem; // 12px
$btn-padding-x: 1.6875rem; // 40px
$btn-font-size: 0.9375rem; // 15px
$btn-font-family: $font-family-poppins;
$btn-font-weight: 500;
$btn-border-radius: 0;
$btn-border-width: 0;
// scss-docs-end btn-variables

// scss-docs-start navbar-variables
$navbar-nav-link-padding-x: .9375rem;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'><path d='M5 12H20M5 17H20M5 7H20' stroke='black' stroke-width='2' stroke-linecap='round'/></svg>");
// scss-docs-end navbar-variables

// scss-docs-start offcanvas-variables
$offcanvas-backdrop-bg: var(--bs-secondary);
$offcanvas-backdrop-opacity: 1;
// scss-docs-end offcanvas-variables

// scss-docs-start box-shadow-variables
$enable-shadows: true;
$box-shadow: 0 0 .375rem rgba($black, .25); // 6px
// scss-docs-end box-shadow-variables

// scss-docs-start dropdown-variables
$dropdown-spacer: 0.4375rem; // 7px
$dropdown-carot: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 14' fill='none'> <path d='M3.20117 5.36276L4.30117 4.26276L7.86917 7.83076L11.4402 4.26576L12.5402 5.36576L7.86917 10.0308L3.20117 5.36276Z' fill='#434F61'/></svg>");
// scss-docs-end dropdown-variables

$grid-gutter-width: 1.875rem;
